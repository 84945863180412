import { useEffect } from 'react';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import SingleProject from './pages/SingleProject';
import Projects from './pages/Projects';
import Planetary from './pages/Planetary'
import Form from './pages/home/Form';
import { PlanetaryProvider } from './hooks/PlanetaryProvider';
import ReactGA from 'react-ga4';
import Header from "./components/ui/header/Header"
import Home from "./pages/home/Home"
import Footer from "./components/ui/footer/Footer"
import { AnimatePresence } from 'framer-motion';
import { Office } from './pages/home/Office';


ReactGA.initialize("G-2LFEY9P05R");


function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
  }, []);

  return (
    <div className="App">
        <BrowserRouter>
          {/*<Header/>*/}
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/office" element={<Office/>}/>
            {/*/<Route path="/projects" element={<Projects/>}/>
            <Route path="/contact" element={<Form/>}/>
            <Route path="/planetary" element={<Planetary/>}/>
            <Route path="/projects/:id" element={<SingleProject/>} />*/}
            <Route path="*" element={<Home/>}/>
          </Routes>
         {/*<Footer/>*/}
        </BrowserRouter>
    </div>
  );
}

export default App;
