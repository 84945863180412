/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 cat.glb 
Author: osmanarici2004_1 (https://sketchfab.com/osmanarici2004_1)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/bengal-cat-non-commercial-ad99670274254e4aa539a90a5dbdb24e
Title: Bengal Cat Non Commercial
*/
//@ts-nocheck

import React, { useEffect } from 'react'
import { useGraph } from '@react-three/fiber'
import { useGLTF, useAnimations } from '@react-three/drei'
import { SkeletonUtils } from 'three-stdlib'

export function Cat(props) {
  const group = React.useRef()
  const { scene, animations } = useGLTF('/office/cat.glb')
  const clone = React.useMemo(() => SkeletonUtils.clone(scene), [scene])
  const { nodes, materials } = useGraph(clone)
  const { actions, names } = useAnimations(animations, group)

  useEffect(() => {
    if (names.length > 0 && actions[names[0]]) {
      console.log('Available animations:', names)
      console.log('Playing animation:', names[0])
      actions[names[0]].play()
    } else {
      console.error('Animation not found or actions not available')
    }
  })
  
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="root">
            <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]}>
              <group name="Sketchfab_model_0" rotation={[-Math.PI / 2, 0, 0]} scale={1.825}>
                <group name="root_1">
                  <group name="GLTF_SceneRootNode_2" rotation={[Math.PI / 2, 0, 0]}>
                    <group name="GLTF_created_0_3">
                      <group name="GLTF_created_0">
                        <primitive object={nodes.GLTF_created_0_rootJoint} />
                        <group name="Object_98_98_correction">
                          <group name="Object_98_98" />
                        </group>
                        <skinnedMesh castShadow name="Object_103" geometry={nodes.Object_103.geometry} material={materials.GoogleAR_StingrayPBS_StingrayPBS} skeleton={nodes.Object_103.skeleton} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/office/cat.glb')
