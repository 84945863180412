/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 office2.glb 
Author: dylanheyes (https://sketchfab.com/dylanheyes)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/minimalistic-modern-office-5540183da1c7452f810f8de33734879a
Title: Minimalistic Modern Office
*/
//@ts-nocheck

import React from 'react'
import { useGLTF } from '@react-three/drei'

export function OfficeModel2(props) {
  const { nodes, materials } = useGLTF('/office/office2.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={0.353}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={[410, 1230, 410]}>
            <mesh receiveShadow geometry={nodes.Minimalistic_Modern_Office_Glass_0.geometry} material={materials.Glass} />
            <mesh receiveShadow  geometry={nodes.Minimalistic_Modern_Office_Structure_0.geometry} material={materials.Structure} />
            <mesh receiveShadow geometry={nodes.Minimalistic_Modern_Office_Background_0.geometry} material={materials.Background} />
            <mesh receiveShadow geometry={nodes.Minimalistic_Modern_Office_Table_0.geometry} material={materials.Table} />
            <mesh receiveShadow geometry={nodes.Minimalistic_Modern_Office_Chair_0.geometry} material={materials.Chair} />
            <mesh receiveShadow geometry={nodes.Minimalistic_Modern_Office_Plants_0.geometry} material={materials.Plants} />
            <mesh receiveShadow geometry={nodes.Minimalistic_Modern_Office_Plants_0_1.geometry} material={materials.Plants} />
            <mesh receiveShadow geometry={nodes.Minimalistic_Modern_Office_Lights_0.geometry} material={materials.Lights} />
            <mesh receiveShadow geometry={nodes.Minimalistic_Modern_Office_LightEmission_0.geometry} material={materials.LightEmission} />
            <mesh receiveShadow geometry={nodes.Carpet_Carpet_0.geometry} material={materials.Carpet} position={[1.001, 0, 2.439]} rotation={[0, -Math.PI / 2, 0]} scale={[1.39, 0.463, 1.39]} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/office/office2.glb')
