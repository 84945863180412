import { Canvas } from '@react-three/fiber'
import React, { Suspense } from 'react'
import { OfficeModel } from '../office/OfficeModel'
import { Loader, OrbitControls, PerspectiveCamera, SpotLight, Stats } from '@react-three/drei'
import { Player5 } from '../office/Player5'
import { Player } from '../office/Player'
import { OfficeModel2 } from '../office/OfficeModel2'
import { Vector3 } from 'three'
import { Cat } from '../office/Cat'

export const Office = () => {
  return (
    <div className="content" style={{backgroundColor: 'black', height:'100vh'}}>
        <Suspense fallback={<Loader />}>
        <Canvas shadows>
            <ambientLight intensity={3} castShadow/>
            <SpotLight position={[0,0,-20]} intensity={10} castShadow rotation={[0, Math.PI, 0]}/>
            <PerspectiveCamera makeDefault position={[0, 4, -100]} rotation={[0, Math.PI, 0]} />
            <OfficeModel2 scale={10} position={[0,0,20]}  rotation={[0,Math.PI/2,0]}/>
            <Player scale={12} position={[5,-15,-80]}  rotation={[0,3.86,0]}/>
            <Cat scale={10} position={[-20,-14,-50]}  rotation={[0,3.86,0]}/>
            <Stats />
        </Canvas>
        </Suspense>
    </div>
  )
}
